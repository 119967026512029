import React, { useRef } from 'react';

import { useNavigate,useParams } from 'react-router-dom';
import Recaptcha from "react-google-recaptcha"

export default function Delisted() {

  const { ipToDelist } = useParams();
  const navigate = useNavigate();
  const captchaRef = useRef(null);

  let whois = (ip) => {
    const url = (ip === undefined || ip === '' || ip === null) ? 'https://api.neoistone.com/cyber/spam/check' : `https://api.neoistone.com/cyber/spam/check/${ip}`;
    fetch(url)
    .then(response => {
      return response.json();
    }, "jsonp")
    .then(res => {
      document.getElementById('loader-wrapper').style.display = 'none';
      if(!res.dnsbl.status)
      {
        navigate('/')
      }
    })
    .catch(err => console.log(err));
  }
  
  whois(ipToDelist)

  const handleSubmit = async (event) => {
    event.preventDefault();
    document.getElementById('loader-wrapper').removeAttribute('style');
    let token = captchaRef.current.getValue();
    captchaRef.current.reset();
    // Handle form submission logic here
    let data = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      company: document.getElementById('company').value,
      ip: document.getElementById('ip').value,
      message: document.getElementById('message').value,
      recaptcha  : token
    }
    try {
      const response = await fetch('https://api.neoistone.com/cyber/spam/delist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        document.getElementById('loader-wrapper').style.display = 'none';
        const result = await response.json();
        alert(result.message);
        if(result.status === 'success')
        {
          navigate('/')
        }
      } else {
         console.log(response.text);
      }
    } catch (error) {
      console.error('Error:', error);
    };
  };
  
  return (
    <>
    <div id="loader-wrapper">
      <div id="loader"></div>
      <div className="loader-section section-left"></div>
      <div className="loader-section section-right"></div>
    </div>
    <section className="main">
      <div className="main-card">
        <div className="card-top">
          <form id="delist" onSubmit={handleSubmit}>
            <h3>Delisted Ip Address</h3>
            <p>Your Ip Address : { ipToDelist }</p>
            <div className="drop-down">
              <input type="text" className="neoistone-input" id="name" name="name" placeholder="Your Name" required />
            </div>
            <div className="drop-down">
              <input type="text" className="neoistone-input" id="email" name="email" placeholder="Your Email Address" required />
            </div>
            <div className="drop-down">
              <input type="text" className="neoistone-input" id="company" name="company" placeholder="Your Company Name (Optional)" />
            </div>
            <div className="drop-down">
              <input type="text" className="neoistone-input" id="ip" name="ip" placeholder="Delisting Ip Address" value={ipToDelist} required />
            </div>
            <div className="drop-down">
              <textarea className="neoistone-input" id="message" name="message" placeholder="Message" required></textarea>
            </div>
            <div className="drop-down recaptcha">
                <Recaptcha sitekey={'6LdS9vApAAAAAAV52_GQTyw7DiPjwohdsvdOveqH'} ref={captchaRef} />
            </div>
            <div className="btn-grp">
              <input type="submit" name="delist" value="Delisted Ip" />
            </div>
          </form>
        </div>
        <div className="card-bottom">
          <p className="copyright">Copyright © 2023, Neoistone Technologies LLP. All Rights Reserved <a href="https://neoistone.com/privacy-policy">privacy and policy</a></p>
        </div>
      </div>
    </section>
    </>
  );
}