import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const  navigate = useNavigate();
  const goback = () => 
  {
    navigate(-1)
  }

  return (
    <div className="not-found">
      <h2>404 - Page Not Found</h2>
      <p>The page you are looking for does not exist.</p>
      <a href={ '' } onClick={ goback() }>Go back to Home</a>
    </div>
  );
}
