import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';




export default function Home() {
  
  const [ip, setIp] = useState('');
  const [blacklisted, setBlacklisted] = useState('');

  let whois = (ip) => {
    const url = (ip === undefined || ip === '' || ip === null) ? 'https://api.neoistone.com/cyber/spam/check' : `https://api.neoistone.com/cyber/spam/check/${ip}`;
    fetch(url)
    .then(response => {
      return response.json();
    }, "jsonp")
    .then(res => {
      document.getElementById('loader-wrapper').style.display = 'none';
      setIp(res.dnsbl.ip)
      setBlacklisted(res.dnsbl.status)
    })
    .catch(err => console.log(err));
  }
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    const ipValue = document.getElementById('ip').value; // Get the value of the input field
    document.getElementById('loader-wrapper').removeAttribute('style');
    whois(ipValue);
  };

  useEffect(() => {
    fetch('https://api.neoistone.com/ip')
    .then(response => {
      return response.json();
    }, "jsonp")
    .then(res => {
      whois(res.ip)
    })
    .catch(err => console.log(err));
  }, []);

  return (
    <>
      <div id="loader-wrapper">
        <div id="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>
      <div className="main">
        <div className="main-card">
          <div className="card-top">
            <form id="recover">
              <h3>Neoistone DNS Blocking</h3>
              <p>Your IP Address: { ip }</p>
              <div className="drop-down">
                <input type="text" className="neoistone-input" id="ip" name="ip" placeholder={ip}  />
              </div>
              <div className="drop-down">
              { blacklisted ? (
                  <p style={{ color: 'red' }}>
                    Your IP {ip} is blacklisted <Link to={ `/delist/${ip}` } >Request delisting</Link>
                  </p>
                ) : (
                  <p style={{ color: 'green' }}>Not blacklisted your IP {ip}</p>
                )}
              </div>
              <div className="btn-grp">
                <button type="submit" id="submit" onClick={handleSubmit}>Check Database</button>
              </div>
            </form>
          </div>
          <div className="card-bottom">
            <p className="copyright">
              Copyright © 2023, Neoistone Technologies LLP. All Rights Reserved{' '}
              <a href="https://neoistone.com/privacy-policy">privacy and policy</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}